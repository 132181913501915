import React, { useState } from "react";
import { Input, Tabs } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { DynamicList } from "../Shared";
import {
  ADD_SIZE_GUIDE,
  DELETE_SIZE_GUIDE,
  EDIT_SIZE_GUIDE,
  FETCH_SIZE_GUIDE,
} from "../../graphql/modules";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Modal from "antd/lib/modal/Modal";
import { errorNotify, successNotify, warnNotify } from "../../util";

const { TabPane } = Tabs;

export const SizeGuide = () => {
  // router history decalare
  const history = useHistory();
  // size type get from url
  const type = new URLSearchParams(useLocation().search).get("type");

  // initialaization state
  const [data, setData] = useState({});
  const [visible, setVisible] = useState(false);
  const [sizeGuideId, setSizeGuideId] = useState("");

  // fetch all guides
  const {
    data: allGuide,
    loading,
    refetch,
  } = useQuery(FETCH_SIZE_GUIDE, {
    variables: {
      type,
    },
  });

  const guideList = allGuide?.FetchSizeGuide?.result || [];

  // add guide
  const [AddGuideMutation, { loading: addLoading }] = useMutation(
    ADD_SIZE_GUIDE,
    {
      variables: {
        data: { ...data, type },
      },
    }
  );
  const addGuide = async () => {
    try {
      const {
        data: { AddSizeGuide },
      } = await AddGuideMutation();
      if (AddSizeGuide.success) {
        successNotify(AddSizeGuide.message);
        closeModal();
        refetch();
      } else {
        warnNotify(AddSizeGuide.message);
      }
    } catch (error) {
      errorNotify(error.message);
      console.log(error.message);
    }
  };

  // edit guide
  const [EditGuideMutation, { loading: editLoading }] = useMutation(
    EDIT_SIZE_GUIDE,
    {
      variables: {
        data: { ...data, type },
        sizeGuideId,
      },
    }
  );

  // delete guide
  const [DeleteGuideMutation, { loading: deleteLoading }] =
    useMutation(DELETE_SIZE_GUIDE);
  const deleteGuide = async (sizeGuideId) => {
    try {
      const {
        data: { DeleteSizeGuide },
      } = await DeleteGuideMutation({
        variables: {
          sizeGuideId,
        },
      });
      if (DeleteSizeGuide.success) {
        successNotify(DeleteSizeGuide.message);
        refetch();
      }
    } catch (error) {
      errorNotify(error.message);
      console.log(error.message);
    }
  };

  // edit guide
  const editGuide = async () => {
    try {
      const {
        data: { EditSizeGuide },
      } = await EditGuideMutation();
      if (EditSizeGuide.success) {
        successNotify(EditSizeGuide.message);
        closeModal();
        refetch();
      } else {
        warnNotify(EditSizeGuide.message);
        closeModal();
      }
    } catch (error) {
      errorNotify(error.message);
      console.log(error.message);
    }
  };

  // control modal
  const openModal = (data) => {
    setData({
      type,
      name: data?.name || "",
      measure_1: data?.measure_1 || "",
      measure_2: data?.measure_2 || "",
    });
    setSizeGuideId(data?._id);
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
    setData({});
    setSizeGuideId("");
  };

  // handle input
  const handleInput = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  // set bottom/top
  //   useEffect(() => {
  //     setData({
  //       ...data,
  //       measure_1: type == 'top' ? 'Upper Chest: ': 'Waist: ',
  //       measure_2: type == 'top' ? 'Lower Chest: ': 'Hips: ',
  //     });
  //   }, [type]);

  // create table column
  const column = [
    {
      title: "Size",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Measurements",
      render: (text, record) => (
        <div>
          <p>
            {type === "top" ? "Upper Chest: " : "Waist: "}
            {record?.measure_1}"
          </p>
          <p>
            {type === "top" ? "Lower Chest: " : "Hips: "}
            {record?.measure_2}"
          </p>
        </div>
      ),
    },
    {
      title: "Actions",
      render: (text, record) => (
        <React.Fragment>
          <button
            onClick={() => {
              openModal(record);
            }}
            style={{ border: "none", background: "transparent" }}
          >
            <i role="button" className="fas fa-pencil mr-2" />
          </button>
          <button
            onClick={() => deleteGuide(record._id)}
            style={{ border: "none", background: "transparent" }}
          >
            <i className="fas fa-trash mr-2" />
          </button>
        </React.Fragment>
      ),
    },
  ];

  // type selection component
  let createBtnForTop = (
    <React.Fragment>
      <button
        onClick={() => setVisible(true)}
        className="btn-common mr-3"
        type="primary"
      >
        <i className="fas fa-plus-circle "></i> Add Guide
      </button>
    </React.Fragment>
  );
  const sizingType = (
    <div>
      <Tabs defaultActiveKey={type} centered>
        <TabPane
          tab={
            <span
              onClick={() => history.push({ search: "?type=top" })}
              className="text-uppercase"
            >
              Top Sizing
            </span>
          }
          key="top"
        >
          <DynamicList
            data={guideList}
            columns={column}
            loading={loading || deleteLoading || editLoading || addLoading}
            actions={createBtnForTop}
          ></DynamicList>
        </TabPane>
        <TabPane
          tab={
            <span
              onClick={() => history.push({ search: "?type=bottom" })}
              className="text-uppercase"
            >
              Bottom Sizing
            </span>
          }
          key="bottom"
        >
          <DynamicList
            data={guideList}
            columns={column}
            loading={loading || deleteLoading || editLoading || addLoading}
            actions={createBtnForTop}
          />
        </TabPane>
      </Tabs>
    </div>
  );

  const disabled = !data.name || !data.measure_1 || !data.measure_2;
  return (
    <DynamicList
      title="Size Guide Setup"
      headerBorderHide={true}
      tableHide={true}
      tableFilter={sizingType}
    >
      <Modal
        title={sizeGuideId ? "Update Guide" : "Add Guide"}
        open={visible}
        onCancel={closeModal}
        onOk={sizeGuideId ? editGuide : addGuide}
        okText={sizeGuideId ? "Update" : "Add"}
        okButtonProps={{
          disabled: disabled || addLoading || editLoading,
          loading: addLoading || editLoading,
        }}
      >
        <div className="mb-3">
          <label>Size*</label>
          <Input
            size="large"
            name="name"
            value={data?.name || ""}
            onChange={handleInput}
            placeholder="Size name"
          />
        </div>
        <div className="mb-3">
          <label>{type === "top" ? "Upper Chest" : "Waist"}*</label>
          <Input
            size="large"
            name="measure_1"
            value={data?.measure_1 || ""}
            onChange={handleInput}
            placeholder={type === "top" ? "Upper Chest" : "Waist"}
          />
        </div>
        <div className="mb-3">
          <label>{type === "top" ? "Lower Chest" : "Hips"}*</label>
          <Input
            size="large"
            name="measure_2"
            value={data?.measure_2 || ""}
            onChange={handleInput}
            placeholder={type === "top" ? "Lower Chest" : "Hips"}
          />
        </div>
      </Modal>
    </DynamicList>
  );
};
