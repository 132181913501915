import React from "react";
import { getFile } from "../../util/helper";

export const OrderItem = ({ data }) => {
  const priceSelect = () => {
    if (
      (data?.bottomSizing && data?.topSizing) ||
      (!data?.bottomSizing && !data?.topSizing)
    ) {
      return data?.price;
    } else if (data?.topSizing) {
      return data?.topPrice;
    } else {
      return data?.bottomPrice;
    }
  };
  const price =
    data?.haveDiscount
      ? parseFloat(
          priceSelect() - (priceSelect() * data?.discount) / 100
        ).toFixed(2)
      : parseFloat(priceSelect() || 0.0).toFixed(2); 
  const image = data.style
    ? data?.product?.styles?.find((st) => st?.name === data?.style)?.url
    : data?.gallery[0];

  return (
    <div className="row mb-3">
      <div className="col-md-4 col-sm-12">
        <div className="product d-flex justify-content-between align-items-center">
          <img
            width="200"
            style={{ aspectRatio: "5/7", objectFit: "cover" }}
            src={getFile(image)}
            alt=""
          />
        </div>
      </div>
      <div className="col-md-4 col-sm-12">
        <div className="details">
          <h6 style={{ width: "200px" }}>{data?.name || "Product"}</h6>
          <p>
            price: {parseFloat(price * (data?.isFreePoolItem ? data?.quantity-1:data?.quantity)).toFixed(2)} USD
            {data?.haveDiscount  && (
                <span className="ml-2">
                  <span style={{ fontStyle: "italic", marginRight: "10px" }}>
                    <strike>
                      {parseFloat(priceSelect() * data?.quantity).toFixed(2)}{" "}
                      USD
                    </strike>
                  </span>
                  <span
                    style={{
                      background: "#F05957",
                      borderRadius: "8px",
                      color: "#fff",
                      padding: "2px 6px",
                    }}
                  >
                    -{data?.discount}%
                  </span>
                </span>
              )}
          </p>
        {!data?.product?.isAccessories && (
            <div className="d-flex">
              Color:
              <div
                className="mx-2"
                style={{
                  background:
                    data?.topColor && data?.bottomColor
                      ? `linear-gradient(90deg, ${data?.topColor} 50%, ${data?.bottomColor} 50%)`
                      : `${data?.topColor || data?.bottomColor}`,
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                  border: "1px solid #2d2d2d",
                  boxShadow: " 0 0 0 2px #f3f5f6 inset",
                  boxSizing: "border-box",
                }}
              />
              <span>
                {data?.topColor && data?.bottomColor
                  ? data?.topColorName === data?.bottomColorName
                    ? data?.topColorName
                    : `${data?.topColorName},${data?.bottomColorName}`
                  : data?.topColorName || data?.bottomColorName}
              </span>
            </div>
          )}
          {data?.style && <p>Style: {data?.style}</p>}
          <p>Qty: {data?.quantity}</p>
          {data?.topSizing && (
            <p>
              Top sizing: {data?.topSizing} {data?.isTopPre && "(Pre-Order)"}
            </p>
          )}
          {data?.bottomSizing && (
            <p>
              Bottom sizing: {data?.bottomSizing}{" "}
              {data?.isBottomPre && "(Pre-Order)"}
            </p>
          )}

          <p style={{ color: "red" }}>{data?.isPreOrderItem && "*Pre-Order"}</p>
        </div>
      </div>
      <div className="col-md-4">
        <div className="mb-3">
          <b className="mb-3" style={{ fontSize: "16px" }}>
            Product code
          </b>
        </div>
        {/* {console.log("data.product?.code ===> ", data.product?.code)} */}
        {Array.isArray(data.product?.code) &&
          data?.product.code.map((item) => (
            <div className="mb-2" key={item}>
              {item + "-" + data?.topSizing + "/" + data?.bottomSizing}
            </div>
          ))}
      </div>
    </div>
  );
};
