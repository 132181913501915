import gql from "graphql-tag";

export const FETCH_ABOUT_CONTACT_CONTENT = gql`
  query FetchDynamicText {
    FetchDynamicText {
      code
      success
      message
      about
      aboutImageOrVideo
      contactUs
      orderNoteLabel
      returnPageRightContent
      returnPageImageOrVideo
      reasonList
      storeCreditDescription
      originalPaymentDescription
      returnPageLeftContent
      ownShippingDescription
      chillfitShippingDescription
      chillfitShippingDetails
    }
  }
`;

export const UPDATE_CONTENT = gql`
  mutation UpdateDynamicText(
    $about: String
    $contactUs: String
    $orderNoteLabel: String
    $aboutImageOrVideo: String
    $returnPageRightContent: String
    $returnPageImageOrVideo: String
    $reasonList: [String]
    $storeCreditDescription: String
    $originalPaymentDescription: String
    $returnPageLeftContent: String
    $ownShippingDescription: String
    $chillfitShippingDescription: String
    $chillfitShippingDetails: String
  ) {
    UpdateDynamicText(
      about: $about
      contactUs: $contactUs
      orderNoteLabel: $orderNoteLabel
      aboutImageOrVideo: $aboutImageOrVideo
      returnPageRightContent: $returnPageRightContent
      returnPageImageOrVideo: $returnPageImageOrVideo
      reasonList: $reasonList
      storeCreditDescription: $storeCreditDescription
      originalPaymentDescription: $originalPaymentDescription
      returnPageLeftContent: $returnPageLeftContent
      ownShippingDescription: $ownShippingDescription
      chillfitShippingDescription: $chillfitShippingDescription
      chillfitShippingDetails: $chillfitShippingDetails
    ) {
      code
      success
      message
    }
  }
`;

export const SENT_NEWSLETTER = gql`
  mutation SentNewsLatter(
    $productDetails: [NewsLatterInput]
    $subject: String
    $title: String
  ) {
    SentNewsLatter(
      productDetails: $productDetails
      subject: $subject
      title: $title
    ) {
      code
      success
      message
    }
  }
`;
