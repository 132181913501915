import gql from "graphql-tag";

export const FETCH_ALL_USER = gql`
  query FetchUsersAdmin(
    $limit: Int
    $offset: Int
    $isGuestUser: Boolean
    $hasCredit: Boolean
    $name: String
  ) {
    FetchUsersAdmin(
      limit: $limit
      offset: $offset
      isGuestUser: $isGuestUser
      hasCredit: $hasCredit
      name: $name
    ) {
      code
      success
      message
      count
      result {
        _id
        firstname
        lastname
        isGuestUser
        avater
        email
        birthDate
        chillfitCash
        phone
        createdAt
      }
    }
  }
`;

export const FETCH_ALL_SUBSCRIBER_USER = gql`
  query FetchSubscriberList {
    FetchSubscriberList {
      code
      success
      message
      result {
        _id
        firstname
        lastname
        email
        phone
      }
    }
  }
`;
