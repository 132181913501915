import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Input, Spin } from "antd";
import { getFile, successNotify, warnNotify } from "../../util";
import {
  // EDIT_BANNER,
  FETCH_SHOP_COLLECTION,
  FILE_UPLOAD,
  UPDATE_SHOP_COLLECTION,
} from "../../graphql/modules";
import ReactQuill from "react-quill";

const ShopCollection = ({}) => {
  const [link, setLink] = useState("");
  const [sectionTitle, setSectionTitle] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [buttonLink, setButtonLink] = useState("");
  const [sectionDescription, setSectionDescription] = useState("");

  // fetch banner
  const { data, refetch, loading } = useQuery(FETCH_SHOP_COLLECTION);
  const collectionData = data?.FetchHomePageSectionFour?.result;

  useEffect(() => {
    if (collectionData) {
      setLink(collectionData?.photo || "");
      setSectionTitle(collectionData?.sectionTitle || "");
      setButtonLink(collectionData?.buttonLink || "");
      setButtonText(collectionData?.buttonText || "");
      setSectionDescription(collectionData?.sectionDescription || "");
    }
  }, [collectionData]);

  // update banner
  const [EditBannerMutation, { loading: updateLoading }] = useMutation(
    UPDATE_SHOP_COLLECTION
  );
  const editBanner = async () => {
    try {
      const {
        data: { UpdateHomePageSectionFour },
      } = await EditBannerMutation({
        variables: {
          photo: link,
          sectionTitle,
          sectionDescription,
          buttonLink,
          buttonText,
          // id: banner?._id,
          // link,
        },
      });
      if (UpdateHomePageSectionFour.success) {
        successNotify(UpdateHomePageSectionFour.message);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // file upload to aws
  const [FileUploadMutaion, { loading: uploadLoading }] =
    useMutation(FILE_UPLOAD);
  const uploadFile = async (e) => {
    const file = e.target.files[0];
    try {
      const {
        data: { SingleUpload },
      } = await FileUploadMutaion({
        variables: {
          file,
        },
      });
      // console.log(SingleUpload)
      if (SingleUpload.success) {
        // let type = file.type.includes("video") ? "video" : "image";
        setLink(SingleUpload.filename);
        // setFileType(type);
        // uploadBanner(SingleUpload.filename, type);
      } else {
        warnNotify(SingleUpload.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Spin spinning={updateLoading}>
      <div className="row">
        <div className="col-md-6">
          <div className="mb-2">
            <div className="mb-2">Section Title</div>
            <Input
              size="large"
              placeholder="Enter section title"
              value={sectionTitle}
              onChange={(e) => setSectionTitle(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <div className="mb-2">Button Text</div>
            <Input
              size="large"
              placeholder="Enter button Text"
              value={buttonText}
              onChange={(e) => setButtonText(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <div className="mb-2">Button Link</div>
            <Input
              size="large"
              placeholder="Enter button link"
              value={buttonLink}
              onChange={(e) => setButtonLink(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <div className="mb-2">Section Description</div>
            <ReactQuill
              modules={ShopCollection.modules}
              value={sectionDescription || ""}
              onChange={(value) => setSectionDescription(value)}
              className="mb-4"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="landing-video">
            <div className="mb-4">
              <Spin spinning={uploadLoading || loading}>
                <div className="mb-2">
                  <img width="100%" src={getFile(link)} alt="" />
                </div>
              </Spin>
              <div className="d-flex justify-content-between align-items-center">
                <input
                  type="file"
                  accept="image/*"
                  // accept="video/*,image/*"
                  onChange={uploadFile}
                  id="video-upload"
                  disabled={uploadLoading}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 text-right">
          <button
            htmlFor="video-upload"
            className="btn-common"
            disabled={!link || !sectionTitle || !sectionDescription}
            onClick={editBanner}
          >
            <i className="fas fa-plus-circle mr-2"></i>Update
          </button>
        </div>
      </div>
    </Spin>
  );
};

export default ShopCollection;

ShopCollection.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "blockquote", "link"],
    [{ indent: "-1" }, { indent: "+1" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};
