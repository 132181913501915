import { DeleteOutlined } from "@ant-design/icons";
import {
  Col,
  Input,
  Row,
  Button,
  Checkbox,
  Spin,
  Switch,
  Tooltip,
  Tag,
  Select,
} from "antd";
import React from "react";
import { useState } from "react";
import Description from "./ProductInfo/Descriptions";
import { PriceInput } from "./ProductInfo/PriceInputBox";
import { catSubType } from "../../config";

export const ProductInfo = ({ product = {}, sizeType, onSubmit, loading }) => {
  const {
    name,
    price,
    topPrice,
    bottomPrice,
    descriptions,
    topDescriptions,
    bottomDescriptions,
    code,
    haveDiscount,
    discount,
    isNewProduct,
    discountType = "all",
  } = product;
  const [infoState, setInfoState] = useState({
    name,
    price,
    topPrice,
    bottomPrice,
    code,
    descriptions,
    topDescriptions,
    bottomDescriptions,
    haveDiscount,
    discount,
    discountType,
    isNewProduct,
  });
  const [codeInput, setCodeInput] = useState("");

  const descriptionType =
    sizeType === catSubType.fullSet
      ? "descriptions"
      : sizeType?.toLowerCase() + "Descriptions";

  // add code
  const onAddCode = () => {
    const code = infoState?.code;
    code.push(codeInput);
    setInfoState({ ...infoState, code });
    setCodeInput("");
  };

  //delete code
  const deleteCode = (name) => {
    const code = [...infoState?.code].filter((item) => item !== name);
    setInfoState({ ...infoState, code });
  };

  //handle descriptions
  const handleDescription = (descriptions) => {
    setInfoState({ ...infoState, [descriptionType]: descriptions });
  };

  // handle input
  const handleInput = (e) => {
    const { name, value } = e.target;
    setInfoState({ ...infoState, [name]: value });
  };

  //checkboxHandle
  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    setInfoState({ ...infoState, [name]: checked, discountType:'' });
  };

  // handle submit
  const handleSubmit = () => {
    const data = {
      ...infoState,
      price: parseFloat(infoState.price),
      topPrice: parseFloat(infoState.topPrice),
      bottomPrice: parseFloat(infoState.bottomPrice),
      discount: parseFloat(infoState.discount),
    };
    onSubmit(data);
  };

  const productPrice =
    sizeType === "FULL_SET"
      ? infoState?.price
      : infoState?.[sizeType?.toLowerCase() + "Price"];

  const disable =
    !infoState.name ||
    productPrice === 0 ||
    (infoState.haveDiscount && infoState.discount < 1) ||
    (infoState.haveDiscount && !infoState.discountType);

  return (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        <Col lg={8}>
          <label>
            Product Name <small className="text-danger h6">*</small>
          </label>
          <Input
            size="large"
            placeholder="Product name"
            value={infoState?.name || ""}
            onChange={handleInput}
            name="name"
          />
        </Col>
        <PriceInput
          handleInput={handleInput}
          infoState={infoState}
          sizeType={sizeType}
        />
        <Col lg={8}>
          <label style={{ width: "50%", marginRight: "10px" }}>
            Sale Discount( %)
            <Checkbox
              checked={infoState?.haveDiscount}
              onChange={handleCheckBox}
              name="haveDiscount"
              className="ml-2"
            ></Checkbox>
          </label>
          <label>Discount On</label>
          <div className="d-flex">
            <Input
              type="number"
              size="large"
              style={{ width: "50%", marginRight: "10px" }}
              disabled={!infoState?.haveDiscount}
              placeholder="Discount"
              onChange={handleInput}
              value={infoState?.discount || ""}
              name="discount"
              suffix={
                <span>
                  <Tooltip title="Price after discount">
                    <Tag color="orange" style={{ fontSize: "14px" }}>
                      {infoState?.discount
                        ? Math.round(
                            productPrice -
                              (infoState.discount * productPrice) / 100
                          )
                        : ""}
                    </Tag>
                  </Tooltip>
                </span>
              }
            />
            <Select
              size="large"
              style={{ width: "50%" }}
              onChange={(value) =>
                setInfoState({ ...infoState, discountType: value })
              }
              value={infoState.discountType}
              disabled={!infoState.haveDiscount}
            >
              <Select.Option key="">Select</Select.Option>
              <Select.Option key="all">All</Select.Option>
              <Select.Option key="instock">In Stock</Select.Option>
              <Select.Option key="preOrder">Pre-Order</Select.Option>
            </Select>
          </div>
        </Col>

        <Col lg={8}>
          <label>Product code</label>
          <Input
            size="large"
            placeholder="Product code"
            onChange={(e) => setCodeInput(e.target.value)}
            name="code"
            value={codeInput}
            suffix={
              <Button type="primary" onClick={onAddCode} disabled={!codeInput}>
                Add
              </Button>
            }
          />
          <div className="mt-3">
            {Array.isArray(product?.code) &&
              infoState?.code?.map((item) => (
                <div
                  className="d-flex justify-content-between align-items-center border mb-2 p-2 rounded bg-white"
                  key={item}
                >
                  <div>{item}</div>
                  <div>
                    <DeleteOutlined
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteCode(item)}
                    />
                  </div>
                </div>
              ))}
          </div>
        </Col>
        <Col lg={16}>
          <label>Product description</label>
          <Description
            data={infoState[descriptionType]}
            onChange={handleDescription}
          />
        </Col>
        <Col lg={16}>
          <label>New tag availibility</label>
          <div>
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              checked={infoState?.isNewProduct}
              onChange={(checked) =>
                setInfoState({ ...infoState, isNewProduct: checked })
              }
            />
          </div>
        </Col>
        <div className="w-100 mt-3 text-right px-2">
          <Button
            className="px-4"
            type="primary"
            disabled={disable || !productPrice}
            onClick={handleSubmit}
          >
            Update
          </Button>
        </div>
      </Row>
    </Spin>
  );
};
