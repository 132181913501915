import gql from "graphql-tag";

export const FETCH_ABOUT_CATEGORY = gql`
  query FetchAbout($limit: Int, $offset: Int) {
    FetchAbout(limit: $limit, offset: $offset) {
      code
      success
      message
      result {
        _id
        name
        aboutItems {
          _id
          name
          mediaLinkWeb
          mediaLinkMobile
          position
        }
      }
    }
  }
`;

export const ADD_ABOUT_CATEGORY = gql`
  mutation AddAboutCategory($name: String!) {
    AddAboutCategory(name: $name) {
      code
      success
      message
    }
  }
`;

export const EDIT_ABOUT_CATEGORY = gql`
  mutation EditAboutCategory($categoryId: ID!, $name: String!) {
    EditAboutCategory(categoryId: $categoryId, name: $name) {
      code
      success
      message
    }
  }
`;

export const DELETE_ABOUT_CATEGORY = gql`
  mutation DeleteAboutCategory($categoryId: ID!) {
    DeleteAboutCategory(categoryId: $categoryId) {
      code
      success
      message
    }
  }
`;

export const ADD_ABOUT_ITEM = gql`
  mutation AddAboutItem(
    $mediaLinkWeb: String!
    $mediaLinkMobile: String!
    $name: String!
    $categoryId: ID!
  ) {
    AddAboutItem(
      mediaLinkWeb: $mediaLinkWeb
      mediaLinkMobile: $mediaLinkMobile
      name: $name
      categoryId: $categoryId
    ) {
      code
      success
      message
    }
  }
`;

export const EDIT_ABOUT_ITEM = gql`
  mutation EditAboutItem(
    $mediaLinkWeb: String!
    $mediaLinkMobile: String!
    $name: String!
    $id: ID!
  ) {
    EditAboutItem(
      mediaLinkWeb: $mediaLinkWeb
      mediaLinkMobile: $mediaLinkMobile
      name: $name
      id: $id
    ) {
      code
      success
      message
    }
  }
`;
export const DELETE_ABOUT_ITEM = gql`
  mutation DeleteAboutItem($id: ID!) {
    DeleteAboutItem(id: $id) {
      code
      success
      message
    }
  }
`;

export const ABOUT_ITEM_LIST_UPDATE = gql`
  mutation UpdateAboutItemPosition($data: [ID]) {
    UpdateAboutItemPosition(data: $data) {
      code
      success
      message
    }
  }
`;
