import { Button, Card } from "antd";
import React, { Fragment } from "react";
import ReactQuill from "react-quill";
import { getFile, warnNotify } from "../../util";
import { FILE_UPLOAD } from "../../graphql/modules";
import { Spin } from "antd";

import { useMutation } from "@apollo/react-hooks";
import ReturnReason from "./ReturnReason";

const OrderPageContent = ({
  onTextHandler,
  contentText,
  saveContent,
  loading,
  aboutImageOrVideo,
  setAboutImageOrVideo,
  setReasonList,
  reasonList,
  storeCreditDescription,
  setStoreCreditDescription,
  originalPaymentDescription,
  setOriginalPaymentDescription,
  returnPageLeftContent,
  setReturnPageLeftContent,
  ownShippingDescription,
  setOwnShippingDescription,
  chillfitShippingDescription,
  setChillfitShippingDescription,
  chillfitShippingDetails,
  setChillfitShippingDetails,
}) => {
  const [FileUploadMutaion, { loading: uploadLoading }] =
    useMutation(FILE_UPLOAD);
  const uploadFile = async (e) => {
    const file = e.target.files[0];
    try {
      const {
        data: { SingleUpload },
      } = await FileUploadMutaion({
        variables: {
          file,
        },
      });
      // console.log(SingleUpload)
      if (SingleUpload.success) {
        // let type = file.type.includes("video") ? "video" : "image";
        setAboutImageOrVideo(SingleUpload.filename);
        // setFileType(type);
        // uploadBanner(SingleUpload.filename, type);
      } else {
        warnNotify(SingleUpload.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <Fragment>
      <Card className="mb-4" title="Order selection instructions">
        <ReactQuill
          modules={OrderPageContent.modules}
          value={returnPageLeftContent || ""}
          onChange={setReturnPageLeftContent}
        />
      </Card>
      <Card className="mb-4" title="Return instructions">
        <ReactQuill
          modules={OrderPageContent.modules}
          value={contentText || ""}
          onChange={onTextHandler}
        />
      </Card>

      <Card className="mb-4" title="Return page background">
        <Spin spinning={uploadLoading}>
          <div className="mb-2">
            <img width="300px" src={getFile(aboutImageOrVideo)} alt="" />
          </div>
        </Spin>
        <div className="d-flex justify-content-between align-items-center">
          <input
            type="file"
            accept="image/*"
            // accept="video/*,image/*"
            onChange={uploadFile}
            id="video-upload"
            disabled={uploadLoading}
          />
        </div>
      </Card>
      <ReturnReason reasonList={reasonList} setReasonList={setReasonList} />
      <Card className="mb-4" title="Chillfit store description">
        <ReactQuill
          modules={OrderPageContent.modules}
          value={storeCreditDescription || ""}
          onChange={setStoreCreditDescription}
        />
      </Card>
      <Card className="mb-4" title="Original payment description">
        <ReactQuill
          modules={OrderPageContent.modules}
          value={originalPaymentDescription || ""}
          onChange={setOriginalPaymentDescription}
        />
      </Card>
      <Card className="mb-4" title="Chillfit shipping description">
        <ReactQuill
          modules={OrderPageContent.modules}
          value={chillfitShippingDescription || ""}
          onChange={setChillfitShippingDescription}
        />
      </Card>
      <Card className="mb-4" title="Own shipping description">
        <ReactQuill
          modules={OrderPageContent.modules}
          value={ownShippingDescription || ""}
          onChange={setOwnShippingDescription}
        />
      </Card>
      <Card className="mb-4" title="Shipping input instruction">
        <ReactQuill
          modules={OrderPageContent.modules}
          value={chillfitShippingDetails || ""}
          onChange={setChillfitShippingDetails}
        />
      </Card>
      <div className="text-right">
        <Button
          type="primary"
          disabled={loading}
          onClick={saveContent}
          loading={loading}
        >
          Save Order Return Contents
        </Button>
      </div>
    </Fragment>
  );
};

export default OrderPageContent;

OrderPageContent.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "blockquote", "link"],
    [{ indent: "-1" }, { indent: "+1" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};
