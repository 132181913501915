import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Card,
  Collapse,
  Input,
  Modal,
  Popconfirm,
  Space,
  Spin,
  Tooltip,
} from "antd";
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import {
  ABOUT_ITEM_LIST_UPDATE,
  ADD_ABOUT_ITEM,
  DELETE_ABOUT_ITEM,
  EDIT_ABOUT_ITEM,
  FAQ_LIST_UPDATE,
  FILE_UPLOAD,
} from "../../graphql/modules";
import { getFile, successNotify, warnNotify } from "../../util";

const { Panel } = Collapse;
const SingleAboutItem = ({
  editFaqCategory,
  category,
  refetch,
  confirmDeleteCategory,
}) => {
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState("");
  const [mediaLinkWeb, setMediaLinkWeb] = useState("");
  const [mediaLinkMobile, setMediaLinkMobile] = useState("");
  const [aboutSubcategoryID, setAboutSubcategoryId] = useState("");
  const [webLoading, setWebLoading] = useState(false);
  const [mobileLoading, setMobileLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mainFaq = category?.aboutItems || [];
  const [allFaq, setAllFaq] = useState(mainFaq);

  useEffect(() => {
    if (mainFaq?.length) {
      setAllFaq(mainFaq);
    }
  }, [mainFaq]);
  // handle modal
  const closeModal = () => {
    setAboutSubcategoryId("");
    setMediaLinkWeb("");
    setMediaLinkMobile("");
    setVisible(false);
  };
  const openModal = (data = {}) => {
    setAboutSubcategoryId(data?._id);
    setMediaLinkMobile(data?.mediaLinkMobile);
    setMediaLinkWeb(data?.mediaLinkWeb);
    setName(data?.name);
    setVisible(true);
  };
  console.log({ mediaLinkMobile, mediaLinkWeb });
  //
  const [AddAboutItemMutaion, { loading: addLoading }] =
    useMutation(ADD_ABOUT_ITEM);
  const addFaq = async () => {
    try {
      const {
        data: { AddAboutItem },
      } = await AddAboutItemMutaion({
        variables: {
          categoryId: category._id,
          mediaLinkMobile,
          mediaLinkWeb,
          name,
        },
      });
      if (AddAboutItem.success) {
        closeModal();
        successNotify(AddAboutItem.message);
        refetch();
      } else {
        warnNotify(AddAboutItem.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  // update faq api
  const [EditAboutMutation, { loading: editLoading }] =
    useMutation(EDIT_ABOUT_ITEM);
  const editFaq = async () => {
    try {
      const {
        data: { EditAboutItem },
      } = await EditAboutMutation({
        variables: {
          id: aboutSubcategoryID,
          mediaLinkWeb,
          mediaLinkMobile,
          name,
        },
      });
      if (EditAboutItem.success) {
        closeModal();
        successNotify(EditAboutItem.message);
        refetch();
      } else {
        warnNotify(EditAboutItem.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // delete faq question
  const [DeleteFaqMutaion] = useMutation(DELETE_ABOUT_ITEM);
  const deleteFaq = async (id) => {
    try {
      const {
        data: { DeleteAboutItem },
      } = await DeleteFaqMutaion({
        variables: {
          id,
        },
      });
      if (DeleteAboutItem.success) {
        successNotify(DeleteAboutItem.message);
        refetch();
      } else {
        warnNotify(DeleteAboutItem.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [FileUploadMutaion, { loading: uploadLoading }] =
    useMutation(FILE_UPLOAD);
  const uploadFile = async (e, type) => {
    if (type === "web") {
      setWebLoading(true);
    } else {
      setMobileLoading(true);
    }
    const file = e.target.files[0];
    try {
      const {
        data: { SingleUpload },
      } = await FileUploadMutaion({
        variables: {
          file,
        },
      });
      if (SingleUpload.success) {
        if (type === "web") {
          setMediaLinkWeb(SingleUpload.filename);
        } else {
          setMediaLinkMobile(SingleUpload.filename);
        }
      } else {
        warnNotify(SingleUpload.message);
      }
    } catch (error) {
      console.log(error.message);
    }
    setWebLoading(false);
    setMobileLoading(false);
  };

  const [DragAndDropMutation] = useMutation(ABOUT_ITEM_LIST_UPDATE);
  const faqDnD = async (data = []) => {
    try {
      const {
        data: { UpdateAboutItemPosition },
      } = await DragAndDropMutation({
        variables: {
          data,
        },
      });
      if (UpdateAboutItemPosition.success) {
        refetch();
      } else {
        warnNotify(UpdateAboutItemPosition.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const onDragEnd = (info) => {
    const sourceIndex = info?.source?.index;
    const destinationIndex = info?.destination?.index;

    if (
      destinationIndex === undefined ||
      destinationIndex === null ||
      sourceIndex === destinationIndex
    ) {
      return;
    }
    const updatedFaq = Array.from(allFaq);

    const [movedItem] = updatedFaq.splice(sourceIndex, 1);
    updatedFaq.splice(destinationIndex, 0, movedItem);
    setAllFaq(updatedFaq);
    faqDnD(updatedFaq.map(({ _id }) => _id));
  };

  // extra
  const getExtra = (data, provided) => (
    <React.Fragment>
      <div className="d-flex justify-content-around align-items-center">
        <div className="mr-2" {...provided.dragHandleProps}>
          <MenuOutlined />
        </div>
        <div className="mr-2">
          <EditOutlined
            onClick={(e) => {
              openModal(data);
              e.stopPropagation();
            }}
          />
        </div>
        <div className="mr-2">
          <Popconfirm
            title="Are you sure?"
            okType="danger"
            onConfirm={(e) => {
              deleteFaq(data._id);
              e.stopPropagation();
            }}
          >
            <DeleteOutlined
              style={{ color: "red" }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Popconfirm>
        </div>
      </div>
    </React.Fragment>
  );

  const getExtraForCategoryCard = () => (
    <React.Fragment>
      <div className="d-flex justify-content-center align-items-center">
        <div className="mr-2">
          <EditOutlined onClick={() => editFaqCategory(category)} />
        </div>
        <div className="mr-2">
          <Tooltip title="Add about page">
            <PlusCircleFilled
              style={{ cursor: "pointer" }}
              onClick={openModal}
            />
          </Tooltip>
        </div>
        <DeleteOutlined
          style={{ color: "red", marginTop: "-9px" }}
          onClick={(e) => {
            confirmDeleteCategory(category?._id);
            e.stopPropagation();
          }}
        />
      </div>
    </React.Fragment>
  );

  // const allFaq = category?.faq || [];
  // console.log("all faq========>", allFaq);

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "lightgray" : "none",
    borderRadius: "8px",
    marginBottom: "8px",
    ...draggableStyle,
  });

  return (
    <Card
      className="mb-4"
      title={category?.name}
      extra={getExtraForCategoryCard()}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {allFaq.map((item, idx) => (
                  <Draggable key={item._id} draggableId={item._id} index={idx}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {console.log("===========item =======> ", item)}
                        <Collapse
                          expandIcon={({ isActive }) => (
                            <CaretRightOutlined
                              rotationslider={isActive ? 90 : 0}
                            />
                          )}
                        >
                          <Panel
                            key="1"
                            header={item?.name}
                            extra={getExtra(item, provided)}
                          >
                            {/* <Answer key="1" answerText={item?.ans} /> */}
                            <div className="d-flex">
                              <div>
                                <p className="mb-3">Web Image</p>
                                {item?.mediaLinkWeb ? (
                                  /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i.test(
                                    getFile(item?.mediaLink)
                                  ) ? (
                                    <img
                                      width="300px"
                                      src={getFile(item?.mediaLinkWeb)}
                                      alt="Media content"
                                    />
                                  ) : (
                                    <video width="300px" controls>
                                      <source
                                        src={getFile(item?.mediaLinkWeb)}
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  )
                                ) : (
                                  <p></p>
                                )}
                              </div>
                              <div className="ml-5">
                                <p className="mb-3">Mobile Image</p>

                                {item?.mediaLinkMobile ? (
                                  /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i.test(
                                    getFile(item?.mediaLink)
                                  ) ? (
                                    <img
                                      width="300px"
                                      src={getFile(item?.mediaLinkMobile)}
                                      alt="Media content"
                                    />
                                  ) : (
                                    <video width="300px" controls>
                                      <source
                                        src={getFile(item?.mediaLinkMobile)}
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  )
                                ) : (
                                  <p></p>
                                )}
                              </div>
                            </div>
                          </Panel>
                        </Collapse>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Space>
      <Modal
        open={visible}
        title={
          aboutSubcategoryID ? "Update about pages content" : "Add about pages"
        }
        onCancel={closeModal}
        okText={aboutSubcategoryID ? "Update" : "Add"}
        okButtonProps={{
          disabled:
            !mediaLinkWeb ||
            !mediaLinkMobile ||
            !name ||
            addLoading ||
            editLoading,
          loading: addLoading || editLoading,
        }}
        onOk={aboutSubcategoryID ? editFaq : addFaq}
      >
        <div className="mb-4">
          <label>About page name*</label>
          <Input
            size="large"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="About page name"
          />
        </div>
        <div className="d-flex gap-2">
          <div>
            <p>Web Image</p>
            <Spin spinning={webLoading}>
              {/* <div className="mb-2">
              <img width="300px" src={getFile(mediaLink)} alt="" />
            </div> */}
              <div className="mb-2">
                {mediaLinkWeb ? (
                  /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i.test(
                    getFile(mediaLinkWeb)
                  ) ? (
                    <img
                      style={{ maxWidth: "49%" }}
                      src={getFile(mediaLinkWeb)}
                      alt="Media content"
                    />
                  ) : (
                    <video style={{ maxWidth: "49%" }} controls>
                      <source src={getFile(mediaLinkWeb)} />
                      Your browser does not support the video tag.
                    </video>
                  )
                ) : (
                  <p></p>
                )}
              </div>
            </Spin>
            <div className="d-flex justify-content-between align-items-center">
              <input
                style={{ maxWidth: "49%" }}
                type="file"
                // accept="image/*"
                accept="video/*,image/*"
                onChange={(e) => uploadFile(e, "web")}
                id="video-upload"
                disabled={uploadLoading}
              />
            </div>
          </div>
          <div>
            <p>Mobile Image</p>
            <Spin spinning={mobileLoading}>
              {/* <div className="mb-2">
              <img width="300px" src={getFile(mediaLink)} alt="" />
            </div> */}
              <div className="mb-2">
                {mediaLinkMobile ? (
                  /\.(jpg|jpeg|png|gif|bmp|svg|webp)$/i.test(
                    getFile(mediaLinkMobile)
                  ) ? (
                    <img
                      style={{ maxWidth: "49%", marginLeft: "10px" }}
                      src={getFile(mediaLinkMobile)}
                      alt="Media content"
                    />
                  ) : (
                    <video
                      style={{ maxWidth: "49%", marginLeft: "10px" }}
                      controls
                    >
                      <source src={getFile(mediaLinkMobile)} />
                      Your browser does not support the video tag.
                    </video>
                  )
                ) : (
                  <p></p>
                )}
              </div>
            </Spin>
            <div className="d-flex justify-content-between align-items-center">
              <input
                style={{ maxWidth: "49%", marginLeft: "10px" }}
                type="file"
                // accept="image/*"
                accept="video/*,image/*"
                onChange={uploadFile}
                id="video-upload"
                disabled={uploadLoading}
              />
            </div>
          </div>
        </div>
      </Modal>
    </Card>
  );
};

export default SingleAboutItem;
