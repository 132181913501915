import { SizeGuide } from "../components/Size";
import { userRole } from "../config";
import {
  ForgotPassword,
  ResetPassword,
  ChagneEmailConfirmation,
  SignIn,
  SignUp,
  AllUser,
  Orders,
  AllCategory,
  // EmailVerification,
  ChangePassword,
  MyProfile,
  CategoryView,
  SubcategoryView,
  Settings,
  LandingPage,
  Faq,
  OrderDetails,
  ContentManagement,
  SubscriberList,
  UpdateProfile,
  Collaborators,
  SingleCollaborator,
  Size,
  Admin,
  Legal,
  EditProduct,
  AddProduct,
  AddMixedProduct,
  EditMixedProduct,
  EGiftCard,
  About,
} from "../pages";
import { ReturnOrder } from "../pages/ReturnOrder";

// Login or Sign in Routes
export const Auth = [
  { path: "/", exact: true, component: SignIn },
  { path: "/signup", exact: true, component: SignUp },
  { path: "/forgot-password", exact: true, component: ForgotPassword },
  { path: "/reset-password/:id", component: ResetPassword },
  { path: "/change-email", exact: true, component: ChagneEmailConfirmation },
];

// Public Routes
export const Public = [
  // { path: "/email-verification", exact: true, component: EmailVerification }
  // { path: "/test", exact: true, component: Test },
  // { path: "/all-test", exact: true, component: AllTestUser }
];

// Private Routes
export const Private = [
  {
    path: "/admin",
    component: Admin,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin],
  },
  {
    path: "/moderator",
    component: Admin,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin],
  },
  {
    path: "/collaborators",
    component: Collaborators,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator],
  },
  {
    path: "/collaborators/:id",
    component: SingleCollaborator,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator],
  },
  {
    path: "/customers",
    component: AllUser,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator],
  },
  {
    path: "/customers/subscriber",
    component: SubscriberList,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator],
  },
  {
    path: "/orders",
    component: Orders,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator],
  },
  {
    path: "/orders/:id",
    component: OrderDetails,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator],
  },
  {
    path: "/return-order-items",
    component: ReturnOrder,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator],
  },
  {
    path: "/categories",
    component: AllCategory,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator],
  },
  {
    path: "/categories/:id",
    component: CategoryView,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator],
  },
  {
    path: "/categories/:id/:id",
    component: SubcategoryView,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator],
  },
  {
    path: "/edit-product/:id",
    component: EditProduct,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator],
  },
  {
    path: "/edit-mixed-product/:id",
    component: EditMixedProduct,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator],
  },
  {
    path: "/add-product/:id",
    component: AddProduct,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator],
  },
  {
    path: "/add-mixed-product/:id",
    component: AddMixedProduct,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator],
  },
  {
    path: "/settings",
    component: Settings,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin],
  },
  {
    path: "/landing-page",
    component: LandingPage,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin],
  },
  {
    path: "/content-management",
    component: ContentManagement,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin],
  },
  {
    path: "/faq",
    component: Faq,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin],
  },
  {
    path: "/about",
    component: About,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin],
  },
  {
    path: "/legal",
    component: Legal,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin],
  },
  {
    path: "/my-profile",
    component: MyProfile,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator],
  },
  {
    path: "/change-password",
    component: ChangePassword,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator],
  },
  {
    path: "/update-profile",
    component: UpdateProfile,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin, userRole.Moderator],
  },
  {
    path: "/sizing",
    component: Size,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin],
  },
  {
    path: "/sizing/guide",
    component: SizeGuide,
    exact: true,
    role: [userRole.superAdmin, userRole.Admin],
  },
];
