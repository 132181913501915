import gql from "graphql-tag";

export const FETCH_ALL_ORDER = gql`
  query FetchAllOrderAdmin(
    $limit: Int
    $offset: Int
    $email: String
    $orderId: String
    $status: String
    $code: String
    $productName: String
    $customerName: String
  ) {
    FetchAllOrderAdmin(
      limit: $limit
      offset: $offset
      email: $email
      orderId: $orderId
      status: $status
      code: $code
      productName: $productName
      customerName: $customerName
    ) {
      code
      success
      message
      count
      result {
        _id
        email
        isGuestUser
        createdAt
        status
        orderId
        grandTotal
        isRefunded
        coupon {
          _id
        }
        user {
          _id
          firstname
          lastname
          phone
          email
          birthDate
        }
        cardType
        items {
          _id
          topSizing
          bottomSizing
          product {
            _id
            name
            price
          }
        }
      }
    }
  }
`;

export const FETCH_SINGLE_ORDER = gql`
  query FetchSingleOrderAdmin($orderId: ID) {
    FetchSingleOrderAdmin(orderId: $orderId) {
      code
      success
      message
      result {
        _id
        email
        status
        orderId
        totalPrice
        grandTotal
        tax
        shippingCherge
        shippingType
        shippingDurationText
        cancelationNote
        trackingNumber
        preOrderTrackingNumber
        preOrderTrackingUrl
        preOrderShippingType
        trackingUrl
        isRefunded
        cardType
        cardNo
        wasPreOrder
        couponDiscount
        orderNote
        discountOnTotalPrice
        isGuestUser
        couponOwner {
          _id
        }
        coupon {
          code
          discountInPercent
        }
        shippingAddress {
          _id
          country
          state
          zipCode
          city
          address
          apertment
          firstname
          lastname
          phone
        }
        billingAddress {
          country
          state
          zipCode
          city
          address
          apertment
          firstname
          lastname
          phone
        }
        items {
          _id
          product {
            _id
            styles {
              name
              url
            }
            isAccessories
            code
          }
          isFreePoolItem
          isShipped
          isPreOrderItem
          isTopPre
          isBottomPre
          topColor
          topColorName
          bottomColor
          bottomColorName
          name
          quantity
          topSizing
          bottomSizing
          style
          price
          gallery
          discount
          discountType
          haveDiscount
          code
          topPrice
          bottomPrice
        }
      }
    }
  }
`;

export const ORDER_STATUS_UPDATE = gql`
  mutation UpdateOrderStatus(
    $orderId: ID
    $status: OrderStatusEnum
    $trackingNumber: String
    $preOrderTrackingNumber: String
    $partialShippedItemsId: [ID]
    $preOrderTrackingUrl: String
    $trackingUrl: String
    $cancelationNote: String
  ) {
    UpdateOrderStatus(
      orderId: $orderId
      status: $status
      trackingNumber: $trackingNumber
      partialShippedItemsId: $partialShippedItemsId
      preOrderTrackingUrl: $preOrderTrackingUrl
      preOrderTrackingNumber: $preOrderTrackingNumber
      trackingUrl: $trackingUrl
      cancelationNote: $cancelationNote
    ) {
      code
      success
      message
    }
  }
`;

export const FETCH_E_GIFT_ADMIN = gql`
  query FetchGiftCard($limit: Int, $offset: Int) {
    FetchGiftCard(limit: $limit, offset: $offset) {
      code
      message
      count
      result {
        _id
        code
        amount
        email
        order {
          _id
        }
      }
    }
  }
`;

export const FETCH_ORDER_ITEMS_ADMIN = gql`
  query FetchOrderIReturnItemsAdmin($limit: Int, $offset: Int) {
    FetchOrderIReturnItemsAdmin(limit: $limit, offset: $offset) {
      code
      message
      count
      result {
        _id
        returnInitDate
        returnStatus
        shippingUrl
        trackingNumber
        returnInitDate
        shippingCompanyName
        customerReturnReasons
        customerReturnNote
        returnPaymentMethod
        returnShippingMethod
        hasArrived
        hasShippingLabelSent
        hasGiftCardSent
        quantity
        order {
          _id
          orderId
        }
        orderItem {
          product {
            _id
            styles {
              name
              url
            }
            isAccessories
          }
          isFreePoolItem
          isShipped

          isPreOrderItem

          discountedPrice
          tax
          isTopPre
          isBottomPre
          topColor
          topColorName
          bottomColor
          bottomColorName
          name
          quantity
          topSizing
          bottomSizing
          style
          price
          gallery
          discount
          discountType
          haveDiscount
          code
          topPrice
          bottomPrice
        }
      }
    }
  }
`;

export const UPDATE_ORDER_ITEM_STATUS = gql`
  mutation UpdateOrderReturnItemStatusAdmin(
    $orderItemId: ID
    $returnStatus: String
    $note: String
  ) {
    UpdateOrderReturnItemStatusAdmin(
      orderItemId: $orderItemId
      returnStatus: $returnStatus
      note: $note
    ) {
      code
      success
      message
    }
  }
`;

export const SEND_SHIPPING_LABEL = gql`
  mutation SendOrderItemLabelAdmin($orderItemId: ID, $file: Upload!) {
    SendOrderItemLabelAdmin(orderItemId: $orderItemId, file: $file) {
      code
      success
      message
    }
  }
`;
export const SEND_EMAIL_NOTIFICATION_ORDER_ITEM = gql`
  mutation SendOrderItemEmails(
    $orderItemId: ID
    $type: String
    $cardType: String
    $last4OfCard: String
  ) {
    SendOrderItemEmails(
      orderItemId: $orderItemId
      type: $type
      cardType: $cardType
      last4OfCard: $last4OfCard
    ) {
      code
      success
      message
    }
  }
`;
