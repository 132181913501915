import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Card, Collapse, Input, Modal, Popconfirm, Space, Tooltip } from "antd";
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import Answer from "./Answer";
import ReactQuill from "react-quill";
import { useMutation } from "@apollo/react-hooks";
import {
  ADD_FAQ_IN_CATEGORY,
  DELETE_FAQ_IN_CATEGORY,
  EDIT_FAQ_IN_CATEGORY,
  FAQ_LIST_UPDATE,
} from "../../graphql/modules";
import { successNotify, warnNotify } from "../../util";

const { Panel } = Collapse;
const SingleFaq = ({
  editFaqCategory,
  category,
  refetch,
  confirmDeleteCategory,
}) => {
  const [visible, setVisible] = useState(false);
  const [qua, setQua] = useState("");
  const [ans, setAns] = useState("");
  const [faqId, setFaqId] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mainFaq = category?.faq || [];
  const [allFaq, setAllFaq] = useState(mainFaq);

  useEffect(() => {
    if (mainFaq?.length) {
      setAllFaq(mainFaq);
    }
  }, [mainFaq]);
  // handle modal
  const closeModal = () => {
    setFaqId("");
    setQua("");
    setAns("");
    setVisible(false);
  };
  const openModal = (data = {}) => {
    setFaqId(data?._id);
    setQua(data?.qua);
    setAns(data?.ans);
    setVisible(true);
  };

  // handle editor input
  const onEditorStateChange = (value) => {
    setAns(value);
  };

  //
  const [AddFAQMutaion, { loading: addLoading }] =
    useMutation(ADD_FAQ_IN_CATEGORY);
  const addFaq = async () => {
    try {
      const {
        data: { AddFAQ },
      } = await AddFAQMutaion({
        variables: {
          categoryId: category._id,
          qua,
          ans,
        },
      });
      if (AddFAQ.success) {
        closeModal();
        successNotify(AddFAQ.message);
        refetch();
      } else {
        warnNotify(AddFAQ.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  // update faq api
  const [EditFAQMutaion, { loading: editLoading }] =
    useMutation(EDIT_FAQ_IN_CATEGORY);
  const editFaq = async () => {
    try {
      const {
        data: { EditFAQ },
      } = await EditFAQMutaion({
        variables: {
          faqId,
          qua,
          ans,
        },
      });
      if (EditFAQ.success) {
        closeModal();
        successNotify(EditFAQ.message);
        refetch();
      } else {
        warnNotify(EditFAQ.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // delete faq question
  const [DeleteFaqMutaion] = useMutation(DELETE_FAQ_IN_CATEGORY);
  const deleteFaq = async (id) => {
    try {
      const {
        data: { DeleteFAQ },
      } = await DeleteFaqMutaion({
        variables: {
          faqId: id,
        },
      });
      if (DeleteFAQ.success) {
        successNotify(DeleteFAQ.message);
        refetch();
      } else {
        warnNotify(DeleteFAQ.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [DragAndDropMutation] = useMutation(FAQ_LIST_UPDATE);
  const faqDnD = async (data = []) => {
    try {
      const {
        data: { UpdateFAQPosition },
      } = await DragAndDropMutation({
        variables: {
          data,
        },
      });
      if (UpdateFAQPosition.success) {
        refetch();
      } else {
        warnNotify(UpdateFAQPosition.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const onDragEnd = (info) => {
    const sourceIndex = info?.source?.index;
    const destinationIndex = info?.destination?.index;

    if (
      destinationIndex === undefined ||
      destinationIndex === null ||
      sourceIndex === destinationIndex
    ) {
      return;
    }
    const updatedFaq = Array.from(allFaq);

    const [movedItem] = updatedFaq.splice(sourceIndex, 1);
    updatedFaq.splice(destinationIndex, 0, movedItem);
    setAllFaq(updatedFaq);
    faqDnD(updatedFaq.map(({ _id }) => _id));
  };

  // extra
  const getExtra = (data, provided) => (
    <React.Fragment>
      <div className="d-flex justify-content-around align-items-center">
        <div className="mr-2" {...provided.dragHandleProps}>
          <MenuOutlined />
        </div>
        <div className="mr-2">
          <EditOutlined
            onClick={(e) => {
              openModal(data);
              e.stopPropagation();
            }}
          />
        </div>
        <div className="mr-2">
          <Popconfirm
            title="Are you sure?"
            okType="danger"
            onConfirm={(e) => {
              deleteFaq(data._id);
              e.stopPropagation();
            }}
          >
            <DeleteOutlined
              style={{ color: "red" }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Popconfirm>
        </div>
      </div>
    </React.Fragment>
  );

  const getExtraForCategoryCard = () => (
    <React.Fragment>
      <div className="d-flex justify-content-center align-items-center">
        <div className="mr-2">
          <EditOutlined onClick={() => editFaqCategory(category)} />
        </div>
        <div className="mr-2">
          <Tooltip title="Add question">
            <PlusCircleFilled
              style={{ cursor: "pointer" }}
              onClick={openModal}
            />
          </Tooltip>
        </div>
        <DeleteOutlined
          style={{ color: "red", marginTop: "-9px" }}
          onClick={(e) => {
            confirmDeleteCategory(category?._id);
            e.stopPropagation();
          }}
        />
      </div>
    </React.Fragment>
  );

  // const allFaq = category?.faq || [];

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? "lightgray" : "none",
    borderRadius: "8px",
    marginBottom: "8px",
    ...draggableStyle,
  });

  return (
    <Card
      className="mb-4"
      title={category?.name}
      extra={getExtraForCategoryCard()}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {allFaq.map((item, idx) => (
                  <Draggable key={item._id} draggableId={item._id} index={idx}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <Collapse
                          expandIcon={({ isActive }) => (
                            <CaretRightOutlined
                              rotationslider={isActive ? 90 : 0}
                            />
                          )}
                        >
                          <Panel
                            key="1"
                            header={item?.qua}
                            extra={getExtra(item, provided)}
                          >
                            <Answer key="1" answerText={item?.ans} />
                          </Panel>
                        </Collapse>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Space>
      <Modal
        open={visible}
        title={faqId ? "Update question and answer" : "Add question and answer"}
        onCancel={closeModal}
        okText={faqId ? "Update" : "Add"}
        okButtonProps={{
          disabled: !qua || !ans || addLoading || editLoading,
          loading: addLoading || editLoading,
        }}
        onOk={faqId ? editFaq : addFaq}
      >
        <div className="mb-4">
          <label>Question*</label>
          <Input
            size="large"
            value={qua}
            onChange={(e) => setQua(e.target.value)}
            placeholder="question"
          />
        </div>
        <div>
          <label>Answer*</label>
          <ReactQuill
            modules={SingleFaq.modules}
            value={ans || ""}
            onChange={onEditorStateChange}
          />
        </div>
      </Modal>
    </Card>
  );
};

export default SingleFaq;

SingleFaq.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "blockquote"],
    [{ indent: "-1" }, { indent: "+1" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};
