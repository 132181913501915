import React, { Fragment } from "react";
import ReactQuill from "react-quill";
import { Button } from "antd";

const ContactContent = ({
  onTextHandler,
  contentText,
  saveContent,
  loading,
}) => {
  return (
    <Fragment>
      <ReactQuill
        modules={ContactContent.modules}
        value={contentText || ""}
        onChange={onTextHandler}
        className="mb-4"
      />
      <div className="text-right">
        <Button
          type="primary"
          disabled={!contentText || loading}
          onClick={saveContent}
          loading={loading}
        >
          Save Contact Us Content
        </Button>
      </div>
    </Fragment>
  );
};

export default ContactContent;

ContactContent.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "blockquote", "link"],
    [{ indent: "-1" }, { indent: "+1" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};
