import React, { useState } from "react";
import { Card, Input, Modal, Popconfirm, Space, Tooltip } from "antd";
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";

const ReturnReason = ({ reasonList, setReasonList }) => {
  const [visible, setVisible] = useState(false);
  const [reason, setReason] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const editReason = () => {
    reasonList[isEditing - 1] = reason;
    setReasonList([...reasonList]);
    closeModal();
  };

  const addReason = () => {
    setReasonList([...reasonList, reason]);
    closeModal();
  };
  const deleteReason = (index) => {
    console.log(index);
    const data = reasonList.filter((_, i) => i !== index);
    setReasonList(data);
  };

  // handle modal
  const closeModal = () => {
    setReason("");
    setVisible(false);
    setIsEditing(false);
  };
  const onEditModal = (data, idx) => {
    setIsEditing(idx);
    setReason(data || "");
    setVisible(true);
  };
  const onAddModal = () => {
    setIsEditing(false);
    setReason("");
    setVisible(true);
  };
  console.log({ isEditing, reason, visible });
  // extra
  const getExtra = (data, idx) => (
    <React.Fragment>
      <div className="d-flex justify-content-around align-items-center">
        <div className="mr-2">
          <EditOutlined
            onClick={() => {
              onEditModal(data, idx + 1);
            }}
          />
        </div>
        <div className="mr-2">
          <Popconfirm
            title="Are you sure?"
            okType="danger"
            onConfirm={() => {
              deleteReason(idx);
            }}
          >
            <DeleteOutlined style={{ color: "red" }} />
          </Popconfirm>
        </div>
      </div>
    </React.Fragment>
  );

  const getExtraForCategoryCard = () => (
    <div className="d-flex justify-content-around align-items-center">
      <div className="mr-2">
        <Tooltip title="Add return reason">
          <PlusCircleFilled
            style={{ cursor: "pointer" }}
            onClick={onAddModal}
          />
        </Tooltip>
      </div>
    </div>
  );

  return (
    <Card
      className="mb-4"
      title="Click on plus icon to add return reason"
      extra={getExtraForCategoryCard()}
      bodyStyle={{ padding: 0 }}
    >
      {reasonList.map((item, idx) => (
        <div
          className="d-flex align-items-center justify-content-between px-4 py-2"
          style={{ width: "100%", borderBottom: "1px solid #f0f0f0" }}
        >
          <p className="mb-0" style={{ fontSize: "18px" }}>
            {idx + 1}. {item}
          </p>
          {getExtra(item, idx)}
        </div>
      ))}
      {!!!reasonList?.length && (
        <p style={{ fontWeight: "", fontStyle: "italic" }}>
          No return reason has been added.
        </p>
      )}
      <Modal
        open={visible}
        title={isEditing ? "Update reason" : "Add reason"}
        onCancel={closeModal}
        okText={isEditing ? "Update" : "Add"}
        okButtonProps={{
          disabled: !reason,
        }}
        onOk={isEditing ? editReason : addReason}
      >
        <div className="mb-4">
          <Input
            size="large"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder="Return reason"
          />
        </div>
      </Modal>
    </Card>
  );
};

export default ReturnReason;

ReturnReason.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "blockquote"],
    [{ indent: "-1" }, { indent: "+1" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};
