import React, { useEffect, useState } from "react";
import {
  DeleteFilled,
  EditFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import { Card, Col, Popconfirm, Row, Spin, Switch, Tag, Tooltip } from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  DELETE_PRODUCT,
  DETACHED_PRODUCT,
  PRODUCT_LIST_UPDATE,
  UPDATE_PRODUCT,
} from "../../graphql/modules";
import {
  errorNotify,
  getFile,
  notify,
  successNotify,
  warnNotify,
} from "../../util";
import { CloneProduct } from "./CloneProduct";
import { useSelector } from "react-redux";
import { catSubType, userRole } from "../../config";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

export const ProductList = ({ refetch, data, from, sizeType }) => {
  const { id } = useParams();

  // const [visible, setVisible] = useState(false);
  // const [selectedProductData, setSelectedProductData] = useState({});

  const { user } = useSelector((state) => state.auth);
  // delete product
  const [products, setProducts] = useState([]);
  useEffect(() => {
    // const allProduct =
    //   data?.FetchSingleSubcategoryAdmin?.result?.products || [];
    if (data) setProducts(data);
  }, [data]);

  const [DeleteProductMutation] = useMutation(DELETE_PRODUCT);
  const deleteProduct = async (productId) => {
    try {
      const {
        data: { DeleteProduct },
      } = await DeleteProductMutation({
        variables: {
          productId,
        },
      });
      if (DeleteProduct.success) {
        successNotify(DeleteProduct.message);
        refetch();
      } else {
        warnNotify(DeleteProduct.message);
      }
    } catch (error) {
      errorNotify(error.message);
    }
  };

  const [DetachedProductMutation] = useMutation(DETACHED_PRODUCT);
  const detachedProduct = async (productId) => {
    const { type, id } = from;
    try {
      const {
        data: { DetachProduct },
      } = await DetachedProductMutation({
        variables: {
          productId,
          [type]: id,
        },
      });
      notify(DetachProduct, refetch());
    } catch (error) {
      console.log(error.message);
    }
  };

  // dnd api
  const [DragAndDropMutation, { loading }] = useMutation(PRODUCT_LIST_UPDATE);
  const productDnD = async (newData = []) => {
    const { type, id } = from;
    const newArray = newData.map((item) => item._id);
    try {
      const {
        data: { UpdateProductPosition },
      } = await DragAndDropMutation({
        variables: {
          data: newArray,
          [type]: id,
        },
      });
      if (!UpdateProductPosition.success) {
        warnNotify(UpdateProductPosition.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const [removed] = products.splice(source.index, 1);
    products.splice(destination.index, 0, removed);
    setProducts([...products]);
    productDnD(products);
  };

  // const [key, setKey] = useState("33");
  // const onEditModal = (item) => {
  //   setSelectedProductData({ ...item });
  //   setKey(Date.now());
  //   setVisible(true);
  // };

  const [EditProductMutation, { loading: statusLoading }] =
    useMutation(UPDATE_PRODUCT);
  const onUpdateProduct = async (entry, value, id) => {
    try {
      const {
        data: { EditProduct },
      } = await EditProductMutation({
        variables: {
          data: { [entry]: value },
          productId: id,
        },
      });
      if (EditProduct.success) {
        // successNotify(
        //   `The product has been ${status === "active" ? "visible" : "hide"} now`
        // );
        refetch();
      } else {
        warnNotify(EditProduct.message);
      }
    } catch (error) {
      errorNotify(error.message);
    }
  };

  return (
    <div>
      <Spin spinning={loading || statusLoading}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                // style={getListStyle(snapshot.isDraggingOver)}
              >
                <Row gutter={[16, 16]}>
                  {Array.isArray(products) &&
                    products.map((item, index) => (
                      <Col lg={24} key={item._id}>
                        <Draggable
                          key={item._id}
                          draggableId={item._id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Card
                                hoverable
                                style={{
                                  width: "100%",
                                  borderColor: "lightgray",
                                  position: "relative",
                                }}
                                cover={
                                  <img
                                    alt="example"
                                    src={getFile(getImageOnSizeType(item))}
                                    style={{
                                      height: "80px",
                                      marginTop: "20px",
                                      width: "auto",
                                      position: "absolute",
                                      right: "15px",
                                      top: "15px",
                                      // aspectRatio: "5/7",
                                      objectFit: "cover",
                                      borderRadius: "0",
                                    }}
                                  />
                                }
                              >
                                <Card.Meta
                                  title={
                                    <div style={{ display: "flex" }}>
                                      <p
                                        style={{
                                          fontSize: "18px",
                                          marginBottom: "0",
                                          marginRight: "10px",
                                        }}
                                      >
                                        {item?.name || "Product Name"}
                                      </p>
                                      {user.role !== userRole.Moderator && (
                                        <span className="d-flex align-items-center">
                                          <Popconfirm
                                            title="Are you sure?"
                                            okText="Delete"
                                            okType="danger"
                                            onConfirm={
                                              [
                                                ...item?.subcategory,
                                                ...item?.category,
                                              ].length > 1
                                                ? () =>
                                                    detachedProduct(item?._id)
                                                : () => deleteProduct(item._id)
                                            }
                                          >
                                            {[
                                              ...item?.subcategory,
                                              ...item?.category,
                                            ].length > 1 ? (
                                              <div>
                                                <i
                                                  className="fas fa-minus-circle mx-1"
                                                  style={{
                                                    color: "red",
                                                    // margin: "5px 0px 0 15px",
                                                  }}
                                                ></i>
                                              </div>
                                            ) : (
                                              <DeleteFilled
                                                style={{
                                                  color: "red",
                                                  // margin: "5px 0px 0 15px",
                                                }}
                                                className="mx-1"
                                              />
                                            )}
                                          </Popconfirm>

                                          <Link
                                            to={`/${sizeType===catSubType.mixed ? 'edit-mixed-product':'edit-product'}/${item?._id}?size-type=${sizeType}`}
                                            // style={{margin: "0 5px"}}
                                          >
                                            <EditFilled
                                              className="mx-1"
                                              style={
                                                {
                                                  // margin: "5px 0px 0 10px",
                                                }
                                              }
                                              // onClick={() => onEditModal(item)}
                                            />
                                          </Link>
                                          <Tooltip
                                            title={
                                              item?.status === "hidden"
                                                ? "The product is hidden now"
                                                : "The product is visible"
                                            }
                                          >
                                            {item?.status === "active" ? (
                                              <EyeOutlined
                                                style={
                                                  {
                                                    // margin: "5px 0px 0 10px",
                                                  }
                                                }
                                                className="mx-1"
                                                onClick={() =>
                                                  onUpdateProduct(
                                                    "status",
                                                    "hidden",
                                                    item?._id
                                                  )
                                                }
                                              />
                                            ) : (
                                              <EyeInvisibleOutlined
                                                style={{
                                                  // margin: "5px 0px 0 10px",
                                                  color: "orangered",
                                                }}
                                                className="mx-1"
                                                onClick={() =>
                                                  onUpdateProduct(
                                                    "status",
                                                    "active",
                                                    item?._id
                                                  )
                                                }
                                              />
                                            )}
                                          </Tooltip>
                                          <Tooltip title="You can update NEW tag visibility">
                                            <Switch
                                              className="mx-1"
                                              checkedChildren="ON"
                                              unCheckedChildren="OFF"
                                              checked={item?.isNewProduct}
                                              onChange={() =>
                                                onUpdateProduct(
                                                  "isNewProduct",
                                                  !item?.isNewProduct,
                                                  item?._id
                                                )
                                              }
                                            />
                                          </Tooltip>
                                          {/* {item?.isNewProduct && (
                                            <Tag color="error" className="mx-1">
                                              NEW
                                            </Tag>
                                          )} */}
                                        </span>
                                      )}
                                    </div>
                                  }
                                  // description={`Price: $${parseFloat(
                                  //   item?.price || 0.0
                                  // ).toFixed(2)}`}
                                  description={
                                    <div className="d-flex">
                                      <div className="mr-5">
                                        <h6
                                          className="mb-1 d-inline-block"
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "700",
                                            borderBottom: "1px solid",
                                            paddingBottom: "2px",
                                          }}
                                        >
                                          Categories
                                        </h6>
                                        {item?.category?.length < 1 && (
                                          <p className="m-0">
                                            <b>No category added</b>
                                          </p>
                                        )}
                                        {item?.category?.map(({ name }) => (
                                          <div
                                            key={name}
                                            className="d-flex align-items-center mb-1"
                                          >
                                            <span>&#8226;</span>
                                            <p
                                              className="mb-0 ml-1"
                                              style={{ fontSize: "12px" }}
                                            >
                                              {name}
                                            </p>
                                          </div>
                                        ))}
                                      </div>
                                      <div>
                                        <h6
                                          className="mb-1 d-inline-block"
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "700",
                                            borderBottom: "1px solid",
                                            paddingBottom: "2px",
                                          }}
                                        >
                                          Subcategories
                                        </h6>
                                        {item?.subcategory?.length < 1 && (
                                          <p className="m-0">
                                            <b>No subcategory added</b>
                                          </p>
                                        )}
                                        {item?.subcategory?.map(({ name }) => (
                                          <div
                                            key={name}
                                            className="d-flex align-items-center mb-1"
                                          >
                                            <span>&#8226;</span>
                                            <p
                                              className="mb-0 ml-1"
                                              style={{ fontSize: "12px" }}
                                            >
                                              {name}
                                            </p>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  }
                                />
                                <div className="d-flex align-items-center mt-3">
                                  {(user.role !== userRole.Moderator && sizeType!=='MIXED') && (
                                    <CloneProduct
                                      productId={item?._id}
                                      isAccessories={item?.isAccessories}
                                      refetch={refetch}
                                      sizeTypeParent={sizeType ==='MIXED' ? item?.sizeType:sizeType}
                                      parentId={id}
                                      productPrice={
                                        item?.sizeType === catSubType.fullSet
                                          ? 0.0
                                          : item[
                                              item?.sizeType?.toLowerCase() + "Price"
                                            ]
                                      }
                                    />
                                  )}
                                  {item?.haveDiscount && (
                                    <Tag className="ml-5" color="red">
                                      {item?.discount}%
                                    </Tag>
                                  )}
                                </div>
                                {item?.isNewProduct && (
                                  <div className="ribbon">New</div>
                                )}
                              </Card>
                            </div>
                          )}
                        </Draggable>
                      </Col>
                    ))}
                </Row>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Spin>
    </div>
  );
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle,
});

const getImageOnSizeType = (data) => {
 
  
  let image = [];
  if (data?.isAccessories) {
    image = data?.gallery;
  } else {
    image =
      data?.sizeType === catSubType.fullSet
        ? data?.colors[0]?.gallery
        : data?.colors[0]?.[data?.sizeType?.toLowerCase() + "Gallery"];
  }
  if(data.name==='Butterfly Trap Thigh High Black')
  console.log(data);
  return Array.isArray(image) && image[0];
};
