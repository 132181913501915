import { Input, Modal, Spin } from "antd";
import React from "react";
import { useState } from "react";
import { DynamicList } from "../components/Shared";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  ADD_ABOUT_CATEGORY,
  DELETE_ABOUT_CATEGORY,
  EDIT_ABOUT_CATEGORY,
  FETCH_ABOUT_CATEGORY,
} from "../graphql/modules";
import { successNotify, warnNotify } from "../util";
import { SingleAboutItem } from "../components/About";

export const About = () => {
  const [name, setName] = useState("");
  const [visible, setVisible] = useState(false);
  const [categoryId, setCategoryId] = useState("");

  // fetch all faq
  const { data, loading, refetch } = useQuery(FETCH_ABOUT_CATEGORY);
  const allFaq = data?.FetchAbout?.result || [];

  // add category faq api
  const [AddFaqCategoryMutation, { loading: addLoading }] =
    useMutation(ADD_ABOUT_CATEGORY);
  const addFaqCategory = async () => {
    try {
      const {
        data: { AddAboutCategory },
      } = await AddFaqCategoryMutation({
        variables: {
          name,
        },
      });
      if (AddAboutCategory.success) {
        closeModal();
        successNotify(AddAboutCategory.message);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // update category faq api
  const [EditFaqCategoryMutation, { loading: editLoading }] =
    useMutation(EDIT_ABOUT_CATEGORY);
  const editFaqCategory = async () => {
    try {
      const {
        data: { EditAboutCategory },
      } = await EditFaqCategoryMutation({
        variables: {
          name,
          categoryId,
        },
      });
      if (EditAboutCategory.success) {
        closeModal();
        successNotify(EditAboutCategory.message);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // delete category faq
  const [DeleteFaqCategoryMutaion] = useMutation(DELETE_ABOUT_CATEGORY);
  const deleteFaqCategory = async (id) => {
    try {
      const {
        data: { DeleteAboutCategory },
      } = await DeleteFaqCategoryMutaion({
        variables: {
          categoryId: id,
        },
      });
      if (DeleteAboutCategory.success) {
        successNotify(DeleteAboutCategory.message);
        refetch();
      } else {
        warnNotify(DeleteAboutCategory.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // confirm modal
  const confirmDeleteCategory = (id) => {
    Modal.confirm({
      content: "If you delete an section then all subsections will be delete",
      onOk: () => deleteFaqCategory(id),
      okType: "danger",
      okText: "Delete",
      cancelText: "Cancel",
    });
  };

  // handle modal
  const closeModal = () => {
    setVisible(false);
    setCategoryId("");
    setName("");
  };
  const openModal = (data = {}) => {
    setCategoryId(data?._id);
    setName(data?.name);
    setVisible(true);
  };

  // const jsx
  let createBtn = (
    <React.Fragment>
      <button
        onClick={() => setVisible(true)}
        className="btn-common"
        type="primary"
      >
        <i className="fas fa-plus-circle mr-2"></i> Create Category
      </button>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <DynamicList
        title="Set About pages content"
        actions={createBtn}
        tableHide={true}
      >
        <Spin spinning={loading}>
          {allFaq.map((item) => (
            <SingleAboutItem
              refetch={refetch}
              key={item._id}
              loading={loading}
              category={item}
              confirmDeleteCategory={confirmDeleteCategory}
              editFaqCategory={openModal}
            />
          ))}
        </Spin>
      </DynamicList>
      <Modal
        open={visible}
        okText={categoryId ? "Update" : "Add"}
        title={categoryId ? "Update subcategory" : "Add subcategory"}
        onOk={categoryId ? editFaqCategory : addFaqCategory}
        onCancel={closeModal}
        okButtonProps={{
          disabled: !name || addLoading || editLoading,
          loading: addLoading || editLoading,
        }}
      >
        <label>Subcategory name:</label>
        <div>
          <Input
            placeholder="Subcategory name"
            size="large"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};
